import Splide from "@splidejs/splide";

jQuery(function ($) {
  $(".toggle-mobile-menu").on("click", () => {
    $("#mobile-menu").toggleClass("-translate-y-full");
    $("html").toggleClass("overflow-y-hidden");
  });
});

const reviews = () => {
  const element = document.getElementById("reviews-slider");

  if (!element) return;

  new Splide(element, {
    type: "carousel",
    perPage: 3,
    perMove: 2,
    arrows: false,
    gap: 24,
    padding: {
      left: "",
      right: "10%",
    },
    width: "100vw",
    breakpoints: {
      640: {
        perPage: 1,
        padding: {
          left: "",
          right: "20%",
        },
      },
      820: {
        perPage: 2,
      },
    },
  }).mount();
};

reviews();

const cb = document.getElementById("kl_newsletter_checkbox");

if (cb) {
  cb.checked = true;
}
